@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
main {
  height: 100%;
  min-height: 100%;
}

video::-webkit-media-controls {
  display: none;
}

.card {
  width: 275px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  gap: 10px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.card > .image {
  width: 70px;
  height: 70px;
  transition: all 0.1s ease-in-out;
}

.card > .number-h1 {
  font-size: 15px;
  font-weight: bold;
  padding: 5px 7px;
  border-radius: 10px;
  color: white;
  background-color: #e57e25;
}

.card > .h1 {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

.card > .p {
  color: #9e9da1;
  font-size: 14px;
  text-align: center;
}
